import InfiniteScroll from "react-infinite-scroll-component"

const GradeStudentSelectionList = ({ students, isEqual, handleSelectStudent, selectedStudent, height }) => {

    return (
        <InfiniteScroll
            dataLength={students.length}
            height={height}
            hasMore={false}
            loader={<h4>Loading...</h4>}
        >
            {
                students.map((student, index) => (
                    <div
                        className={
                            isEqual(student, selectedStudent) ?
                            "student-container selected-student"
                            :
                            "student-container"

                        }
                        key={index}
                        onClick={() => handleSelectStudent(student)}
                    >
                        <span
                            className="h6"
                        >
                            {student.display_name}
                        </span>
                    </div>
                ))
            }
        </InfiniteScroll>
    )

}

export default GradeStudentSelectionList