import React, { useEffect, useState } from "react"

import moment from 'moment'
import Swal from 'sweetalert2'
import { useAuth0 } from "@auth0/auth0-react"

import {
    Col,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    Row }
from 'reactstrap';
import ReactPlayer from 'react-player'

import { PTPlayer } from "../utils/sessionData"
import { getSessionByID } from "../api/external/session"


const ReplayModal = (props) => {
    var replayObj = null

    const { getAccessTokenSilently } = useAuth0()

    const [session, setSession] = useState({})

    useEffect(() => {
        const getSession = async ( sessionID ) => {
            Swal.fire({
                title: 'Please Wait!',
                text: 'Getting Session.',
                allowOutsideClick: false,
                willOpen: () => {
                    Swal.showLoading()
                },
            });

            try {
                const accessToken = await getAccessTokenSilently({
                    scope: "openid profile offline_access read:class create:session email"
                })

                const { data } = await getSessionByID(sessionID, accessToken)
                setSession(data)
                Swal.close()
            } catch (error) {
                const { data } = await getSessionByID(sessionID, props.token)
                setSession(data)
                Swal.close()
            }
        }

        props.session.sessionID && props.isOpen && getSession(props.session.sessionID)
    }, [props, getAccessTokenSilently])

    const handleSVGDraw = (ref) => {
        const obj = PTPlayer(ref, session.drawing)
        replayObj = obj
    }

    const closeBtn = (
        <button className="close" onClick={props.toggle} type="button">
          &times;
        </button>
    );

    return (
        <div>
            {
                session.sessionID ?
                <Modal
                    centered
                    size="lg"
                    unmountOnClose={ true }
                    isOpen={props.isOpen}
                    toggle={props.toggle}
                    onClosed={() => {
                        setSession({})
                    }}
                >
                    <ModalHeader close={closeBtn} toggle={() => { props.toggle()}}>
                        <div>
                            {props.student.display_name}
                        </div>
                        <div>
                            { moment(session.endTime).format("dddd, MMM Do YYYY - h:mm A") }
                        </div>
                    </ModalHeader>
                    <ModalBody style={{ width: "100%", height: "488px"}}>
                        <Container className="fill-height" fluid>
                            <svg
                                style={{width: "100%", height: "435px"}}
                                id="drawing"
                                ref={re => {
                                    if(!re) return
                                    handleSVGDraw(re)
                                }}
                            />
                            <Row>
                                <Col>
                                    <ReactPlayer
                                        controls={true}
                                        url={[
                                            session.audio_url,
                                            session.audio_url_mp3,
                                        ]}
                                        width={"100%"}
                                        height={"100%"}
                                        progressInterval={0.00001}
                                        onProgress={({ playedSeconds }) => {
                                            if(replayObj){
                                                replayObj.drawToTime(playedSeconds);
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
                :
                ''
            }
        </div>
    );
}

export default ReplayModal;