import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLink,
  faPowerOff,
  faUser,
  faPencilAlt,
  faMicrophone,
  faChevronLeft,
  faArrowLeft,
  faArrowRight,
  faSchool,
  faHome
} from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faPencilAlt);
  library.add(faPencilAlt);
  library.add(faMicrophone);
  library.add(faChevronLeft);
  library.add(faArrowLeft);
  library.add(faArrowRight);
  library.add(faSchool);
  library.add(faHome);
}

export default initFontAwesome;