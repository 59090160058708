import React, { useEffect, useState } from 'react';

import { useAuth0 } from "@auth0/auth0-react"
import { getSessionLetterSoundsByChild } from "../api/external/session"

import {
    Popover,
    PopoverHeader,
    PopoverBody,
    TabContent,
    TabPane,
} from 'reactstrap';
import { isEqual, isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'reactstrap/lib';
import { levelsEn, levelsEs } from "../utils/defaults";

import LetterSoundButton from './LetterSoundButton';
import GradeLevelSelection from './GradeLevelSelection';
import GradeStudentSelectionList from './GradeStudentSelectionList';
import EnglishVowelSection from './EnglishVowelSection';
import SpanishVowelSection from './SpanishVowelSection';
import moment from 'moment';


const Grades = (props) => {
    const {
        setIL,
        setTL,
        targetLevel,
        independentLevel,
        letters,
        setLetters,
        lettersMap,
        setLettersMap,
        students,
        submitSession,
        setSelectedStudent,
        selectedStudent,
        toggle,
        isOpen,
        teacher,
        sessionID
    } = props;

    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

    const [ showLetter, setShowLetter ] = useState(null)
    const [ activeTab, setActiveTab] = useState(1);
    const [ readyToSubmit, setReadyToSubmit ] = useState(false)
    const [ letterSoundsLevelList, setLetterSoundsLevelList ] = useState(["IS", "ES", "MS", "AP", "WP", "Let", "Si", "Si+", "SiAP", "AL"])

    const createMapList = (mapObject, letterList, mapObjectProperty) => {
        for ( var letter of letterList ){
            mapObject[mapObjectProperty][letter.letter] = letter.count
        }
        return mapObject
    }

    const handleLevelOption = async ( type, option ) => {
        if (type === "independent"){
            setIL( option )
        }
        else{
            setTL( option )
            
            await getLetterSounds(selectedStudent.ID, option.lang)
            .then(( letters ) => {
                // console.log("letters in navigator whiteboard", letters)
                let newLettersMap = {
                    'consonants': {},
                    'long': {},
                    'short': {}
                }
                // console.log("letters", letters)
                newLettersMap = createMapList(newLettersMap, letters.consonants, 'consonants')
                newLettersMap = createMapList(newLettersMap, letters.vowels.long, 'long')
                newLettersMap = createMapList(newLettersMap, letters.vowels.short, 'short')
                // console.log("new letters map", newLettersMap)
                setLettersMap(newLettersMap)
                setLetters(letters)
            })
        }
        
    }

    const checkForLetterKeys = (map) => {
        for (const key in map) {
            if (map.hasOwnProperty(key)) {
                if (Object.keys(map[key]).length === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    };

    const isEvaluated = () => (
        !isEmpty(independentLevel) &&
        !isEmpty(targetLevel)
    );

    const toggleTab = tab => {
        if (tab === 3 && !isEvaluated()) {
            return
        }
        

        if(activeTab !== tab) setActiveTab(tab);
    }

    // get letters by sound  for student
    const getLetterSounds = async ( student, lang ) => {
        try {
            const accessToken = await getAccessTokenSilently({
                scope: "openid profile offline_access read:class create:session email"
            })

            const data = await getSessionLetterSoundsByChild(student, accessToken, lang)
            return data
            
        } catch (error) {
            const accessToken = await getAccessTokenWithPopup({
                scope: "openid profile offline_access read:class create:session email"
            })
    
            const data = await getSessionLetterSoundsByChild(student, accessToken, lang)
            return data
        }
    }
    // https://github.com/mars/create-react-app-buildpack
    const handleSelectStudent =  async ( student, initialLevel ) => {
        setSelectedStudent( student );
        setIL({"lang": "EN", level:""})
        setTL({"lang": "EN", level:""})
        // grab student letters
    }

    const setSessionStartAndDateUsed = ()=> {
        let today = moment().format('MM/DD/YYYY')
        let date_used_list = (object) => {
            let mapCountKey = object.letter_type === 'consonant' ? 'consonants' : object.vowel_type ;
            console.log("map count key", mapCountKey)
            let newCount;
            if ( object.count !== lettersMap[mapCountKey][object.letter] ) {
                newCount = object.count - lettersMap[mapCountKey][object.letter]
                console.log("new count object.count > lettersMap['mapcountkey'][object.letter]", newCount)
                return [
                    ...object.date_used,
                    { 'date': today, 'count':newCount, sessionId: sessionID }
                ]
            } else {
                return object.date_used
            }

            // if ( !object.date_used.includes(today) ) {
            //     return [
            //         ...object.date_used,
            //         today
            //     ]
            // } else {
            //     return object.date_used
            // }
        }
        setLetters( (prevState) =>{
            return {
                ...prevState,
                consonants: prevState.consonants.map( object => {
                    return object.count !== lettersMap.consonants[object.letter] ? {
                        ...object,
                        session_start_count:object.count,
                        date_used: date_used_list(object)
                    } : object
                }),
                vowels: {
                    long: prevState.vowels.long.map( object =>{
                        return object.count !== lettersMap.long[object.letter] ? {
                            ...object,
                            session_start_count:object.count,
                            date_used: date_used_list(object)
                        } : object
                    }),
                    short: prevState.vowels.short.map( object =>{
                        return object.count !== lettersMap.short[object.letter] ? {
                            ...object,
                            session_start_count:object.count,
                            date_used: date_used_list(object)
                        } : object
                    })
                },
                targetLanguage: targetLevel.lang ? targetLevel.lang : '',
                student_id: selectedStudent.ID
            }
        })
    }

    const handleSubmit = () => {
        if (!selectedStudent.ID){
            return
        }

        setSessionStartAndDateUsed()
        setReadyToSubmit(true)
    }

    useEffect(()=>{
        if(readyToSubmit){
            submitSession();
        }
    }, [readyToSubmit])


    const onLetterButtonClick = ( e ) => {
        let vowel_type;
        if ( e.target.vowel_type ) {
            vowel_type = e.target.vowel_type
        }

        setLetters( (prevState) => {
            if ( e.target.letter_type === 'consonant'){
                return {
                    ...prevState,
                    consonants : prevState.consonants.map( object => {
                        return object.letter === e.target.name && object.letter_type ===  e.target.letter_type ? {
                            ...object,
                            letter:e.target.name,
                            count:e.target.value,
                        } : object
                    })
                }
            } else if ( e.target.letter_type === 'vowel' )  {
                if ( vowel_type === 'long' ){
                    return {
                        ...prevState,
                        vowels : {
                            ...prevState.vowels,
                            long : prevState.vowels.long.map( object => {
                                return object.letter === e.target.name && object.letter_type ===  e.target.letter_type ? {
                                    ...object,
                                    letter:e.target.name,
                                    count:e.target.value,
                                } : object
                            })
                        }
                    }
                } 

                return {
                    ...prevState,
                    vowels : {
                        ...prevState.vowels,
                        short : prevState.vowels.short.map( object => {
                            return object.letter === e.target.name && object.letter_type ===  e.target.letter_type ? {
                                ...object,
                                letter:e.target.name,
                                count:e.target.value,
                            } : object
                        })
                    }
                }
            }
            
        })
    }


    const slideCount = letterSoundsLevelList.includes(targetLevel.level) ? 3 : 2
    const slideCountMap = {
        1: !selectedStudent.name,
        2: !targetLevel.level
    }

    return (
        <div>
            <Popover 
                flip
                isOpen={ isOpen }
                target="DataPopover"
                toggle={ toggle }
                placement="bottom-start"
                
            >
                <PopoverHeader>
                    <div className="d-flex justify-content-between align-items-center py-2">
                        <div className="text-primary cursor-pointer"
                            onClick={() => {
                                activeTab === 1 ?
                                toggle()
                                :
                                toggleTab(activeTab - 1)
                            }}
                        >
                            {
                                activeTab === 1 ?
                                <span>
                                    Cancel
                                </span>
                                :
                                <span>
                                    <FontAwesomeIcon 
                                        icon={faChevronLeft}
                                        className="mr-2"
                                    />
                                    Back
                                </span>
                            }
                        </div>
                        <div ><h5 className="m-0">Dynamic Assessment</h5></div>
                        <div className={!isEvaluated() ? "disabled" : slideCountMap[activeTab] ? "disabled" : "text-primary cursor-pointer"}
                            onClick={() => {
                                activeTab < slideCount && isEvaluated() ?
                                toggleTab(activeTab + 1)
                                :
                                handleSubmit()
                            }}
                        >
                            { 
                                activeTab !== slideCount ? 
                                <span 
                                    className={!isEvaluated() ?
                                        "disabled"
                                        : slideCountMap[activeTab] ?
                                        "disabled": 
                                        ""
                                    }
                                >
                                    Next
                                    <FontAwesomeIcon 
                                        icon={faChevronRight}
                                        className="ml-2"
                                    />
                                </span>
                            :
                                <span 
                                    className={!isEvaluated() ?
                                        "disabled"
                                        : slideCountMap[activeTab] ?  
                                        "disabled":
                                        ""
                                    }
                                >
                                    Submit 
                                </span> 
                            }
                        </div>
                    </div>
                </PopoverHeader>
                <PopoverBody
                    className={ activeTab === 1 ? "p-0" : ""}
                    style={{background:'#FFFFFF', width:400}}
                >
                    <TabContent activeTab={activeTab}>
                        {/* Evaluation */}
                        <TabPane tabId={1}>
                            <Row>
                                <Col xs="12" className='mb-3 pt-3'>
                                    <span style={{fontSize:18, fontWeight:500}} className='ml-2'><i>Select student</i></span>
                                </Col>
                            </Row>
                            { students.length > 0 ? (
                                <GradeStudentSelectionList 
                                    students={students}
                                    handleSelectStudent={handleSelectStudent} 
                                    selectedStudent={selectedStudent} 
                                    height={538}  
                                    isEqual={isEqual}  
                                />
                            ): (
                                <Row className="d-flex justify-content-center align-items-center py-2" style={{height:538}}>
                                    <Col xs="12" className="text-center">
                                        <div className="spinner-border text-primary" role="status" style={{ width: '5rem', height: '5rem' }}>
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <p style={{fontSize: 20}}>Loading students...</p>
                                    </Col>
                                </Row>
                            )}
                        </TabPane>
                        {/* Students */}
                        {/* IF TARGET LEVEL IS ES AND ABOVE */}
                        <TabPane tabId={ 2 }>
                            <Container>
                                <Row>
                                    <Col xs="12">
                                        <div className="text-center mt-2">
                                            <span style={{fontSize:18, fontWeight:500}}><i>Tap the corresponding level for the child</i></span>
                                        </div>
                                        <div className="mt-4">
                                            <div className="text-center">
                                                <h5 style={{ color: "#F48B30"}}>
                                                    <u>Independent Level</u>
                                                </h5>
                                            </div>
                                            <GradeLevelSelection
                                                header={"ENGLISH"}
                                                levels={levelsEn}
                                                clickHandler={handleLevelOption}
                                                levelType={"independent"}
                                                isEqual={isEqual}
                                                level={independentLevel}
                                            />
                                            <GradeLevelSelection
                                                header={"ESPAÑOL"}
                                                levels={levelsEs}
                                                clickHandler={handleLevelOption}
                                                levelType={"independent"}
                                                isEqual={isEqual}
                                                level={independentLevel}
                                            />
                                        </div>
                                        <div className="mt-5 mb-3">
                                            <div className="text-center">
                                            <h5 style={{ color: "#F48B30"}}>
                                                    <u>Target Level</u>
                                                </h5>
                                            </div>
                                            <GradeLevelSelection
                                                header={"ENGLISH"}
                                                levels={levelsEn}
                                                clickHandler={handleLevelOption}
                                                levelType={"target"}
                                                isEqual={isEqual}
                                                level={targetLevel}
                                            />
                                            <GradeLevelSelection
                                                header={"ESPAÑOL"}
                                                levels={levelsEs}
                                                clickHandler={handleLevelOption}
                                                levelType={"target"}
                                                isEqual={isEqual}
                                                level={targetLevel}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </TabPane>
                        {letterSoundsLevelList.includes(targetLevel.level) &&
                        <TabPane tabId={slideCount}>
                            <Container>
                                { !checkForLetterKeys(lettersMap) ? (
                                    <Row className="d-flex justify-content-center align-items-center py-2" style={{height:538}}>
                                        <Col xs="12" className="text-center">
                                            <div className="spinner-border text-primary" role="status" style={{ width: '5rem', height: '5rem' }}>
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            <p style={{fontSize: 20}}>Loading letter sounds...</p>
                                        </Col>
                                    </Row>
                                ): (
                                    <>
                                        <Row className='d-flex justify-content-start py-2 border-bottom m-auto'>
                                            <Col xs="12" className='p-2'>
                                                <span style={{fontSize:18, fontWeight:500}}><i>Select all letter sounds used correctly</i></span>
                                                <label className='text-secondary'>* Holding down letter will activate count box</label>
                                            </Col>
                                            <Col xs="12" className="px-2 pt-2">
                                                <p><strong>CONSONANTS</strong></p>
                                            </Col>
                                            { letters.consonants?.map((letterObj, index)=>{
                                                return(
                                                    <Col key={index} xs="2" className='p-2'>
                                                        <LetterSoundButton showLetter={showLetter} setShowLetter={setShowLetter} onLetterButtonClick={onLetterButtonClick} letterObj={letterObj}/>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                        <Row className='d-flex justify-content-start py-3 m-auto'>
                                            { targetLevel.lang === 'EN' ? (
                                                <EnglishVowelSection 
                                                    targetLvl={targetLevel}
                                                    longVowelLetters={letters.vowels.long}
                                                    shortVowelLetters={letters.vowels.short} 
                                                    showLetter={showLetter} 
                                                    setShowLetter={setShowLetter} 
                                                    onLetterButtonClick={onLetterButtonClick}
                                                />
                                            )
                                            :
                                            (
                                                <SpanishVowelSection
                                                    targetLvl={targetLevel} 
                                                    vowelLetters={letters.vowels.long} 
                                                    showLetter={showLetter} 
                                                    setShowLetter={setShowLetter} 
                                                    onLetterButtonClick={onLetterButtonClick}
                                                />
                                            )
                                            }
                                        </Row>
                                    </>
                                )}
                            </Container>
                        </TabPane> }
                    </TabContent>
                </PopoverBody>
            </Popover>
        </div>
    );
}

export default Grades;