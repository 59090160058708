import axios from "axios";
import { domain } from "../auth";
import { createConsonantsList, createVowelList } from '../../utils/defaults'

export const postDWASession = async ( data, key ) => {
    const header = {
        "Authorization": `Bearer ${key}`,
        "Content-Type": 'application/json'
    }

    const endpoint = domain + "/sessions/new"

    const response = await axios.post(endpoint, data, { headers: header })
    // console.log("response", response)
    return response
}

export const getSessionByChild = async ( childID, key ) => {
    const header = {
        "Authorization": `Bearer ${key}`,
    }

    const endpoint = domain + "/api/get/sessions/" + childID

    const response = await axios.get(endpoint, { headers: header })

    return response
}


export const getSessionByID = async ( sessionID, key ) => {
    const header = {
        "Authorization": `Bearer ${key}`,
    }

    const endpoint = domain + "/api/get/session/" + sessionID

    const response = await axios.get(endpoint, { headers: header })

    return response
}

// const setSessionStartCount = ( letterList ) => {
//     for ( let letter of letterList ) {
//         letter['session_start_count'] = letter['count']
//     }
//     return letterList
// }

export const getSessionLetterSoundsByChild = async ( childID, key, lang ) => {
    //  check for current letter sounds object in the mongo database

    const header = {
        "Authorization": `Bearer ${key}`,
    }
    
    const endpoint = `${domain}/api/letter-sounds/${childID}/${lang}`

    const response = await axios.get(endpoint, { headers: header })

    console.log("response", response.data)

    return response.data

}