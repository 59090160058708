import React from "react";

import { Row, Col } from "reactstrap";
import { Button } from 'reactstrap';
import { Navbar } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuth0 } from "@auth0/auth0-react";

import { FOOTER_VERSION } from "../utils/defaults";


const Footer = () => {
  const { user, isAuthenticated, logout } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <Navbar fixed="bottom" className="footer text-center" expand="md">
        <Row className="w-100">
          {
            isAuthenticated &&
              <Col>
                <Button
                  color="danger"
                  size="sm"
                  style={{
                      backgroundColor: "#ffffff",
                      borderColor: "#F48B30",
                      color: "#F48B30",
                      borderWidth: "0.5em",
                      borderRadius: "0.75em",
                      paddingRight: "2.5em",
                      paddingLeft: "2.5em",
                  }}
                  onClick={() => logoutWithRedirect() }
              >
                <FontAwesomeIcon icon="power-off" className="mr-3" /> Log out
              </Button>{' '}
            </Col>
          }
          <Col><p><strong>DWA Version </strong>{FOOTER_VERSION}</p></Col>
          { isAuthenticated && (
            <Col>
              
                
                  <p>
                    <strong>Logged in as:</strong> {user && user.email}
                  </p>
                
              
            </Col>
          )}
        </Row>
    </Navbar>
  )
};

export default Footer;
