import { useState, useCallback, useEffect } from 'react';
import ConfettiExplosion from 'confetti-explosion-react';
import { 
    Badge,
    Button,
    Input
} from 'reactstrap/lib';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLongPress, LongPressDetectEvents } from 'use-long-press';
import Star from "../assets/yellow_star.png"

const LetterSoundButton = ({ onLetterButtonClick, letterObj, showLetter, setShowLetter }) => {
    const [isExploding, setIsExploding] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(null)

    const callback = useCallback(() => {
        let letter = {
            "letter":letterObj['letter'], 
            "letter_type":letterObj["letter_type"],
            "count":letterObj["count"],
            "vowel_type": letterObj["letter_type"] === 'vowel' ? letterObj["vowel_type"] : null
        } 
        setShowLetter(letter)
    }, [letterObj, setShowLetter]);

    let target = {
        name:letterObj["letter"],
        value:letterObj["count"] + 1,
        letter_type:letterObj["letter_type"],
        vowel_type: letterObj["letter_type"] === 'vowel' ? letterObj["vowel_type"] : null
    } 

    const addCountFromModal = (e)=> {
        e.preventDefault()
        if ( letterObj["count"] >= 0 && letterObj["count"] < 4 ){
            onLetterButtonClick({
                target: {
                    name:letterObj["letter"],
                    value:letterObj["count"] + 1,
                    letter_type:letterObj["letter_type"],
                    vowel_type: letterObj["letter_type"] === 'vowel' ? letterObj["vowel_type"] : null
                }
            });
            if(letterObj["count"] === 3){
                setButtonClicked(letterObj["letter"])
                setIsExploding(true)
            }
            setTimeout(()=>{
                setIsExploding(false)
                setButtonClicked(null)
            }, 300)
        }
    }

    const subtractCountFromModal = (e)=> {
        e.preventDefault()
        if ( letterObj["count"] >= 0 && letterObj["count"] < 5 ){
            onLetterButtonClick({
                target: {
                    name:letterObj["letter"],
                    value:letterObj["count"] > 0 ? letterObj["count"] - 1 : letterObj["count"],
                    letter_type:letterObj["letter_type"],
                    vowel_type: letterObj["letter_type"] === 'vowel' ? letterObj["vowel_type"] : null
                }
            });
            if(letterObj["count"] === 3){
                setButtonClicked(letterObj["letter"])
                setIsExploding(true)
            }
            setTimeout(()=>{
                setIsExploding(false)
                setButtonClicked(null)
            }, 300)
        }
        
    }

    const bind = useLongPress(  callback, {
        onCancel: (e)=> {
            e.preventDefault()
            if ( letterObj["count"] >= 0 && letterObj["count"] < 4 ){
                onLetterButtonClick({
                    target: target
                });
                if(letterObj["count"] === 3){
                    setButtonClicked(letterObj["letter"])
                    setIsExploding(true)
                }
                setTimeout(()=>{
                    setIsExploding(false)
                    setButtonClicked(null)
                }, 300)
            }
        },
        // onMove: () => console.log("Detected mouse or touch movement"),
        threshold: 500,
        captureEvent: true,
        cancelOnMovement: false,
        cancelOutsideElement: true,
        detect: LongPressDetectEvents.BOTH
    })

    const handlers = bind("test context");

    const buttonColorMap = {
        0: 'white',
        1: '#D1E6DD',
        2: '#75B798',
        3: '#208654'
    }

    const textColorMap = {
        0: '#6C757D',
        1: 'gray',
        2: 'black',
        3: 'white'
    }

    const renderShowCountBox = ( letterObj, showLetter ) => {
        if (!showLetter) {
            return false;
        }

        if ( showLetter['letter_type'] === 'consonant' ) {
            return showLetter["letter"] === letterObj['letter'] && showLetter["letter_type"] === letterObj["letter_type"]
        } else {
            return showLetter["letter"] === letterObj['letter'] && showLetter["letter_type"] === letterObj["letter_type"] && showLetter["vowel_type"] === letterObj["vowel_type"]
        }

    }

    return (

        <div style={{position:'relative', marginBottom:5}}>
            <>
                <button
                    id={letterObj["letter"]}
                    style={{ 
                        position:'relative',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        width:38, 
                        height:38, 
                        fontSize:16, 
                        background:buttonColorMap[letterObj["count"]],
                        color:textColorMap[letterObj["count"]]
                    }}
                    className='btn btn-outline-secondary letter-button'
                    {...handlers}
                >
                    {letterObj["count"] === 4 && <img src={Star} style={{height:38, width:38, position:'absolute', bottom:0, right:0, zIndex:10}}/>}
                    <span style={{position:'absolute', zIndex:11}}>{letterObj["letter"]}</span>
                    {buttonClicked === letterObj["letter"] && letterObj["count"] === 4 && isExploding && <ConfettiExplosion force={0.4} duration={4000} particleCount={40} height={600} width={600} />}
                </button>
                {renderShowCountBox( letterObj, showLetter ) && (
                    <div className="letter-popover">
                        <div className='row justify-content-center p-3 align-items-center m-auto w-100 h-100' style={{position:'relative'}}>
                            <button className="btn btn-outline" style={{position:'absolute', top:0, right:0}} onClick={()=>setShowLetter(null)}>
                                <FontAwesomeIcon  color={'#6C757D'} icon={faTimes}/>
                            </button>
                            <div className="w-100 text-center mb-2" style={{fontSize:18, fontWeight:500, color:'#6C757D'}}>
                                {letterObj["letter"]}
                            </div>
                            <Button 
                                onClick={subtractCountFromModal}
                                style={{borderRadius:'8px 0px 0px 8px'}}><FontAwesomeIcon icon={faMinus}/></Button>
                            <Input  value={letterObj['count']} style={{borderRadius:0}} className="w-50 bg-white text-center" readOnly/>
                            <Button
                                onClick={addCountFromModal} 
                                style={{borderRadius:'0px 8px 8px 0px'}}
                            >
                                <FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </div>
                    </div>
                )}
                { letterObj["count"] !== 0 && 
                    <Badge className="text-white" color="danger" style={{position:'absolute', top:-8, right:0, borderRadius:50, padding:'4px 6px'}}>
                        {letterObj["count"]}
                    </Badge>
                }
            </>

        </div>


    )

}

export default LetterSoundButton