import { REF_WIDTH, REF_HEIGHT } from "./defaults";

export const refRes = {width: REF_WIDTH, height: REF_HEIGHT};
const { innerWidth: width, innerHeight: height } = window;

let defaultRatio = width / refRes.width

if (refRes.height * defaultRatio > height)
    defaultRatio = height / refRes.height

export const ratio = defaultRatio
export const boardSize = {
    width: refRes.width * ratio,
    height: refRes.height * ratio
}

export const resizeImage = (url, width, height, type, callback) => {
    var sourceImage = new Image();

    sourceImage.onload = function() {
        // Create a canvas with the desired dimensions
        var canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        // Scale and draw the source image to the canvas
        canvas.getContext("2d").drawImage(sourceImage, 0, 0, width, height);

        // Convert the canvas to a data URL in `type`: - image/png or image/jpeg format
        callback(canvas.toDataURL(type ? type : 'image/png'));
    }

    sourceImage.src = url;
}


export const pointToViewPort = ( point, viewport ) => {
    const scaledPoint = ( point / ratio )
    return ( scaledPoint + (REF_WIDTH * viewport))
}