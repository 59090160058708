import React, { useContext, useEffect, useState } from "react";

import moment from 'moment';
import Swal from 'sweetalert2'
import { isEmpty, orderBy } from "lodash";
import { useParams } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

import {
    Col,
    Card,
    CardImg,
    CardText,
    CardBody,
    CardTitle,
    Button,
    Container,
    Row
} from 'reactstrap'

import Loading from "../components/Loading"
import ReplayModal from "../components/Replay"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StudentsContext } from "../contexts/StudentsContext";

import history from "../utils/history";
import { GALLERY_SESSIONS_MESSAGE, GALLERY_SESSIONS_TITLE, levelsEn, levelsEs } from "../utils/defaults"

import { getSessionByChild } from "../api/external/session"

const languages = {
    "EN": levelsEn,
    "ES": levelsEs
}

const Gallery = () => {
    const { studentID } = useParams()
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

    const studentsContext = useContext(StudentsContext)

    const [sessions, setSessions] = useState([])
    const [selectedSession, setSelectedSession] = useState({});

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        Swal.fire({
            title: GALLERY_SESSIONS_TITLE,
            text: GALLERY_SESSIONS_MESSAGE,
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        });

        const getSessions = async ( child ) => {
            try {
                const accessToken = await getAccessTokenSilently({
                    scope: "openid profile offline_access read:class create:session email"
                })

                const { data } = await getSessionByChild(child, accessToken)
                setSessions(orderBy(data, ["endTime"], ['desc']))
                Swal.close()
            } catch (error) {
                const accessToken = await getAccessTokenWithPopup({
                    scope: "openid profile offline_access read:class create:session email"
                })

                const { data } = await getSessionByChild(child, accessToken)
                setSessions(orderBy(data, ["endTime"], ['desc']))
                Swal.close()
            }
        }

        getSessions( studentID )
    }, [studentID, getAccessTokenSilently, getAccessTokenWithPopup])

    const viewSession = ( session ) => {
        toggle()
        setSelectedSession(session)
    }

    const goBack = () => {
        studentsContext.resetSelectedStudent()
        history.push("/start")
    }

    return (

        <Container fluid className="p-0">
            <div className="border-bottom" style={{height:'8vh'}}>
                <div className="row h-100 align-items-center m-auto">
                    <div className="col-2 col-xl-1">
                        <button className="btn btn-block float-left go-back shadow" onClick={() => {goBack()}}>
                            <FontAwesomeIcon icon="chevron-left" /><span className="d-none d-md-inline-block">&nbsp;&nbsp;BACK</span>
                        </button>
                    </div>
                    <div className="col-10 col-md-8 col-xl-9 text-center text-white">
                        <span className="gallery-session-title">
                            {
                                isEmpty(studentsContext.selectedStudent) ?
                                ''
                                :
                                `${studentsContext.selectedStudent.display_name} - `
                            }
                            Sessions
                        </span>
                    </div>
                    <div className="col-2 col-xl-1 d-none d-md-inline-block">

                    </div>
                </div>
            </div>
            <Row className="m-auto pt-4 gallery-row" style={{height:'92vh', overflow:'scroll'}}>
            {
                sessions.map(session => (
                    <Col lg key={ session.sessionID } className="col-12 col-md-6 col-xl-3 mb-3">
                        <Card className=" shadow h-100">
                            <CardImg
                                top
                                height="50%"
                                width="100%"
                                src={session.thumbnail_url}
                                alt="Session Thumbnail" 
                                style={{borderRadius:'8px 8px 0px 0px'}}
                            />
                            <div className="mr-2 text-right text-muted" style={{ backgroundColor: "#FFFFFF"}}>
                                <span>
                                    { moment(session.endTime).format("dddd, MMM Do YYYY") }                                    
                                </span>
                            </div>
                            <CardBody className="pt-1" style={{ backgroundColor: "#F0F3F5", borderRadius: '0px 0px 8px 8px'}}>
                                    <Row className="align-items-center justify-content-between py-2 h-100">
                                        <Col className="col-6 text-center text-success">
                                            <div>
                                                <span className="h4 m-0">{ moment(session.endTime).format("h:mm A") }</span>
                                            </div>
                                            <div className="text-muted text-uppercase">
                                                <span className="h6">Created At</span>
                                            </div>
                                        </Col>
                                        <Col className="col-6 text-center text-primary">
                                            <div>
                                                <span className="h4 m-0">{ Math.round(session.duration) } secs</span>
                                            </div>
                                            <div className="text-muted text-uppercase">
                                                <span className="h6">Duration</span>
                                            </div>
                                        </Col>
                                        <Col className="col-12">
                                            <Row className="m-auto py-2 align-items-center justify-content-center assessment-container">
                                                {
                                                    languages[session.targetLanguage].map(level => (
                                                        <div
                                                            key={`${session.targetLanguage}-${level.level}`}
                                                            className={
                                                            session.targetLevel === level.level ?
                                                            "selected-option"
                                                            :
                                                            ''
                                                        }>
                                                            <span className="p-2">{ level.level }</span>
                                                        </div>
                                                    ))
                                                }
                                            </Row>
                                            <CardText className="mt-3 text-muted text-center text-uppercase">Dynamic Assessment</CardText>
                                            <Button
                                                className="view-btn w-100"
                                                onClick={() => { viewSession( session )}}
                                            >
                                                View
                                            </Button>
                                        </Col>
                                    </Row>
                                
                            </CardBody>
                        </Card>
                    </Col>
                ))
            }
            </Row>
            <ReplayModal isOpen={modal} toggle={toggle} session={selectedSession} student={studentsContext.selectedStudent}/>
        </Container>
    )
}

export default withAuthenticationRequired(Gallery, {
    onRedirecting: () => <Loading />
}); 