// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.carousel-control-prev-icon,
.carousel-control-next-icon{
    filter: invert(1);
    width:100%;
}

.visually-hidden{
    display: none;
}

.carousel-control {
    margin:10px;
    height: 100px;
    width: 100px;
    top: 85%;
    z-index: 250;
    background: #ffffff;
    border: 10px solid #fcba03;
    border-radius: 25%; /*zero for square */
}

.visually-hidden{
    display:none;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/carousel.css"],"names":[],"mappings":";AACA;;IAEI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,QAAQ;IACR,YAAY;IACZ,mBAAmB;IACnB,0BAA0B;IAC1B,kBAAkB,EAAE,mBAAmB;AAC3C;;AAEA;IACI,YAAY;AAChB","sourcesContent":["\n.carousel-control-prev-icon,\n.carousel-control-next-icon{\n    filter: invert(1);\n    width:100%;\n}\n\n.visually-hidden{\n    display: none;\n}\n\n.carousel-control {\n    margin:10px;\n    height: 100px;\n    width: 100px;\n    top: 85%;\n    z-index: 250;\n    background: #ffffff;\n    border: 10px solid #fcba03;\n    border-radius: 25%; /*zero for square */\n}\n\n.visually-hidden{\n    display:none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
