import axios from "axios";
import { domain } from "../auth";

export const postDWAFiles = async (file, contentType, key) => {
    const header = {
        "Authorization": `Bearer ${key}`,
        'Content-Type' : 'multipart/form-data'
    }

    const endpoint = domain + "/api/session/upload"

    let data = new FormData()
    data.append('content_type', contentType)
    data.append('file', file)

    const response = await axios.post(endpoint, data, { headers: header})

    return response
}