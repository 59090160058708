import * as d3 from "d3";
import { chunk, forEach, head, isEqual, last, uniqWith } from "lodash";
import { v4 as uuidv4 } from 'uuid';

import dataURItoBlob from "./uriToBlob";
import { resizeImage, pointToViewPort } from "./ratios"
import { 
    VERSION,
    IS_ERASER,
    IS_FINISHED,
    REF_WIDTH,
    REF_HEIGHT,
    PAGE_SIZE,
    POINT_PRECISION
} from "./defaults";

export const formatSessionData = ( start, data, drawingData ) => {
    let ratio = drawingData.ratio

    let shapes = []
    let imageBlobs = []
    let imageURLs = []

    forEach(drawingData.shapes, (board, index) => {
        let drawing = JSON.parse(board.segments)

        const withFixedPrecision = num => {
            return parseFloat(num.toFixed(POINT_PRECISION));
        }

        const createPointSegment = point => [
            withFixedPrecision(pointToViewPort(point.x, index)),
            withFixedPrecision(point.y / ratio),
        ];

        resizeImage(
            board.imageURL,
            REF_WIDTH,
            REF_HEIGHT,
            "image/jpeg",
            ( imageURL ) => {
                imageBlobs.push(dataURItoBlob( imageURL ))
                imageURLs.push( imageURL )
            }
        )
        
        forEach(drawing.lines, ( line ) => {
            let points = uniqWith(line.points, isEqual)

            let startPoint = head(points)

            let chunks = chunk(points, 2)

            let segments = []
            forEach(chunks, ( value )=> {
                let first = head( value )
                let tail = last( value )

                if (!(first && tail)){
                    return
                }

                segments.push({
                    "a": createPointSegment(first),
                    "b": createPointSegment(tail),
                    "timestamp": (tail.timestamp - start) / 1000, // Timestamp in Seconds
                    "viewport": index,
                    "width": line.brushRadius // Value = 5; Constant.
                })
            })

            let shape = {
                "id": uuidv4(),
                "isEraser": IS_ERASER,
                "isFinished": IS_FINISHED,
                "segments": segments,
                "start": createPointSegment(startPoint),
                "strokeColor": line.brushColor,
                "strokeWidth": line.brushRadius,
                "timestamp": ( startPoint.timestamp - start ) / 1000, // Timestamp in Seconds
                "type": "Pen",
                "viewport": String(index)
            }
            shapes.push(shape)
        })
    })

    return {
        "sessionData":  {
            ...data,
            "drawing": {
                "pages": PAGE_SIZE,
                "shapes": shapes,
                "size": [
                    REF_WIDTH * PAGE_SIZE,
                    REF_HEIGHT
                ],
                "version": VERSION,
                "viewportAnimations": drawingData.viewportAnimations
            }
        },
        "imageBlobs": imageBlobs,
        "imageURLs": imageURLs
    }
}

function midPointBtw(p1, p2) {
    return {
      x: p1.x + (p2.x - p1.x) / 2,
      y: p1.y + (p2.y - p1.y) / 2
    };
}

export const PTPlayer = (ref, data) => {
    var obj = {
        "myName": "PTPlayer",
        "el": ref,
        "data": data,
        "pages": data.pages || 1.0,
        "currentTime": 0.0,
        "svg": d3.select(ref),
    }

    obj.pageWidth = data.size[0] / obj.pages
    obj.pageHeight = data.size[1]

    obj.x = d3
        .scaleLinear()
        .domain(
            [0, obj.pageWidth]
        )
        .range(
            [0, ref.clientWidth]
        )
    obj.y = d3
        .scaleLinear()
        .domain(
            [0, obj.pageHeight]
        )
        .range(
            [0, ref.clientHeight]
        )

    // Draw Shape
    obj.drawShape = (shape, untilTime, viewportChanged) => {
        if (untilTime === undefined) {
            untilTime = 1e6;
        }

        var prevTimestamp = shape.timestamp;
        forEach(shape.segments, (segment, index) => {
            if (
                segment.timestamp <= untilTime &&
                (
                    (segment.timestamp > obj.currentTime) ||
                    viewportChanged
                ) 
            ) {
                var duration = (segment.timestamp - prevTimestamp)
                obj.drawSegment(
                    segment,
                    shape.strokeColor,
                    duration,
                    shape.id,
                    index > 0 ? shape.segments[index - 1] : null
                )
                prevTimestamp = shape.timestamp
            }
        })
    }

    // Set ViewPort
    obj.setViewport = ( viewport ) => {
        if(viewport.x < 0) viewport.x = 0.0;
        if(viewport.y < 0) viewport.y = 0.0;

        if(viewport.width <= 0) viewport.width = 1024;
        if(viewport.height <= 0) viewport.height = 768;

        obj.x = d3
            .scaleLinear()
            .domain([viewport.x, viewport.x + viewport.width])
            .range([0, ref.clientWidth]);

        obj.y = d3
            .scaleLinear()
            .domain([viewport.y, viewport.y + viewport.height])
            .range([0, ref.clientHeight]);

        if ( viewport.viewportID ) {
          obj.viewportID = viewport.viewportID;
        }
        else {
          obj.viewportID = Math.round( viewport.x / viewport.width).toString();
        }

        obj.svg.selectAll("path")
            .transition()
            .duration( 750 )
            .ease( d3.easeSinInOut );
    }

    // Draw Segment
    obj.drawSegment = ( segment, color, duration, shapeID, prevSegment ) => {
        if(duration === undefined){
          duration = 0;
        }

        var node = document.getElementById(
            "#" + shapeID + "_" + segment.timestamp
        )
        
        if(node){
          return;
        }

        var midPoint = midPointBtw(
            {
                x: segment.a[0],
                y: segment.a[1]
            },
            {
                x: segment.b[0],
                y: segment.b[1]
            }
        )

        var ctx = d3.path();
        ctx.moveTo(
            obj.x(segment.a[0]),
            obj.y(segment.a[1])
        );        

        var path = obj.svg.append('path')
            .style( 'stroke', color )
            .style( "stroke-width", segment.width )
            .attr( "timestamp", segment.timestamp )
            .attr( "id", shapeID + "_" + segment.timestamp )
            .attr('d', ctx.toString());

        if (prevSegment !== null) {
            let connectPoint = midPointBtw(
                {
                    x: prevSegment.b[0],
                    y: prevSegment.b[1]
                },
                {
                    x: segment.a[0],
                    y: segment.a[1]
                }
            )

            let connectPointEnd = midPointBtw(
                {
                    x: connectPoint.x,
                    y: connectPoint.y
                },
                {
                    x: prevSegment.b[0],
                    y: prevSegment.b[1]
                },
            )
            

            ctx.bezierCurveTo(
                obj.x( connectPoint.x ),
                obj.y( connectPoint.y ),
                obj.x( connectPointEnd.x ),
                obj.y( connectPointEnd.y ),
                obj.x( prevSegment.b[0] ),
                obj.y( prevSegment.b[1] ),
            )
        }
    
        ctx.quadraticCurveTo(
            obj.x( midPoint.x ),
            obj.y( midPoint.y ),
            obj.x(segment.b[0]),
            obj.y(segment.b[1])
        )
    
        path.transition()
            .duration(duration * 1000)
            .ease( d3.easeLinear )
            .attr( 'd', ctx.toString() );
    }

    obj.drawToTime = ( time ) => {
        var viewportTime = 0.0;
        var viewportChanged = false;

        if(obj.data.viewportAnimations && obj.data.viewportAnimations.length > 0 ){
            var currentViewport = obj.data.viewportAnimations[0];

            for (var i = 0; i < obj.data.viewportAnimations.length; i++) {
                if(obj.data.viewportAnimations[i].timestamp <= time){
                    currentViewport = obj.data.viewportAnimations[i];
                }
            }

            if(obj.viewportID !== null && currentViewport.viewportID !== obj.viewportID){
                obj.setViewport(currentViewport);
                viewportChanged = true;
            }

            viewportTime = currentViewport.timestamp;
        }
        
        if( time < obj.currentTime || viewportChanged ) {
          if ( obj.data.version && obj.data.version > 0.9 ) {
            let laterPaths = document.getElementsByTagName('path');
                
            laterPaths = [...laterPaths].filter(( i ) => 
                    (
                        parseFloat( i.getAttribute("timestamp") ) > time) ||
                        (parseFloat( i.getAttribute("timestamp") ) < viewportTime &&
                        i.getAttribute("viewportID") !== obj.data.viewportID 
                    )
                );
            forEach(laterPaths, ( o ) => ( o.remove() ))
          }
          else {
            let laterPaths = document.getElementsByTagName('path');
            laterPaths = [...laterPaths].filter(( i ) =>
                (
                    ( parseFloat( i.getAttribute("timestamp") ) > time ) ||
                    ( parseFloat( i.getAttribute("timestamp") ) < viewportTime )
                )
            );

            forEach(laterPaths, ( o ) => ( o.remove() ))
          }
        }


        var isLessThanTime = ( s ) => {
          if ( obj.data.version && obj.data.version > 0.9 ){
            return (s.timestamp <= time) && (s.timestamp > viewportTime);
          }
          else {
            return (
                (
                    ( s.timestamp <= time ) &&
                    ( s.timestamp > viewportTime )
                ) ||
                ( s.viewport === obj.viewportID )
            )
          }
        }

        var validShapes = obj.data.shapes.filter(isLessThanTime);

        for (let i = 0; i < validShapes.length; i++) {
            obj.drawShape(validShapes[i], time, viewportChanged);
        }
        obj.currentTime = time;
    }

    if( obj.data.viewportAnimations && obj.data.viewportAnimations.length > 0 ){
        obj.setViewport(obj.data.viewportAnimations[0]);
    }
    return obj
}
