import LetterSoundButton from "./LetterSoundButton";
import { Col } from "reactstrap";

const SpanishVowelSection = ({ vowelLetters, showLetter, setShowLetter, onLetterButtonClick }) => {

    return (
        <>
            <Col xs="12" className='p-2'>
                <span><strong>VOWELS</strong></span>
            </Col>
            { vowelLetters?.map((letterObj, index)=>{
                return(
                    <Col key={index} xs="2" className='p-2'>
                        <LetterSoundButton showLetter={showLetter} setShowLetter={setShowLetter} onLetterButtonClick={onLetterButtonClick} letterObj={letterObj}/>
                    </Col>
                )
            })}
        </>

    )
}

export default SpanishVowelSection;