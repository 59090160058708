// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.modal-content{
  border-radius: 8px;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.popover{
  background-color: rgba(255, 255, 255, 0.95) !important;
}

.popover-body, .popover-inner{
  background:transparent !important;
}

.fa-star{
  color:#ffc107;
}

/* Scroll */
.gallery-row::-webkit-scrollbar {
  width:10px;
  border-radius: 0px 8px 8px 0px;
}

/* Track */
.gallery-row::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0px 8px 8px 0px;
}

.gallery-row::-webkit-scrollbar-thumb {
  background: #29363D;
  border:3px solid #4FBAEA;
  border-radius: 10px;
} `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA,6CAA6C;AAC7C;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,sDAAsD;AACxD;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;AACf;;AAEA,WAAW;AACX;EACE,UAAU;EACV,8BAA8B;AAChC;;AAEA,UAAU;AACV;EACE,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,wBAAwB;EACxB,mBAAmB;AACrB","sourcesContent":[".next-steps .fa-link {\n  margin-right: 5px;\n}\n\n/* Fix for use only flexbox in content area */\n.next-steps .row {\n  margin-bottom: 0;\n}\n\n.next-steps .col-md-5 {\n  margin-bottom: 3rem;\n}\n\n@media (max-width: 768px) {\n  .next-steps .col-md-5 {\n    margin-bottom: 0;\n  }\n}\n\n.modal-content{\n  border-radius: 8px;\n}\n\n.spinner {\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  width: 100vw;\n  background-color: white;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n\n.result-block-container .result-block {\n  opacity: 1;\n}\n\n.popover{\n  background-color: rgba(255, 255, 255, 0.95) !important;\n}\n\n.popover-body, .popover-inner{\n  background:transparent !important;\n}\n\n.fa-star{\n  color:#ffc107;\n}\n\n/* Scroll */\n.gallery-row::-webkit-scrollbar {\n  width:10px;\n  border-radius: 0px 8px 8px 0px;\n}\n\n/* Track */\n.gallery-row::-webkit-scrollbar-track {\n  background: transparent;\n  border-radius: 0px 8px 8px 0px;\n}\n\n.gallery-row::-webkit-scrollbar-thumb {\n  background: #29363D;\n  border:3px solid #4FBAEA;\n  border-radius: 10px;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
