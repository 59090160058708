

const GradeLevelSelection = ({ header, levels, clickHandler, levelType, isEqual, level }) => {

    return (
        <div className="mt-3">
            <p><strong>{header}</strong></p>
            <div className="d-flex justify-content-between">
                {
                    levels.map((option, index) => 
                        <div
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() => clickHandler(levelType, option)}
                            className={ isEqual(option, level) ? "selected-option option" : 'option'}
                        >
                            <span>{ option.level }</span>
                        </div>
                    )
                }
            </div>
        </div>
    )


}

export default GradeLevelSelection