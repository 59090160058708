import React, { createContext, useState } from "react";

import { initialStudent } from "../utils/defaults"

export const StudentsContext = createContext();

export const StudentsProvider = ({ children }) => {

    const [students, setStudents] = useState([])
    const [selectedStudent, setStudent] = useState(initialStudent)
    const [teacher, setTeacher] = useState()

    const resetSelectedStudent = () => {
        setStudent(initialStudent)
    }

    const resetTeacher = () => {
        setTeacher()
    }

    return (
        <StudentsContext.Provider
            value={{
                students,
                setStudents,

                selectedStudent,
                setStudent,

                teacher,
                setTeacher,

                resetTeacher,
                resetSelectedStudent
            }}
        >
            { children }
        </StudentsContext.Provider>
    );
}