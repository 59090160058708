import React from "react";
import Timer from "react-compound-timer";

const StopWatch = ( props ) => {

    return (
        <Timer>
            {() => (
                <div fluid="true" className="text-center" style={ props.style }>
                    <div className="d-flex justify-content-center">
                        <div className="p-1" style={{ width: "2.3em"}}><strong>REC </strong></div>
                        <div className="p-1" style={{ width: "3em"}}><Timer.Minutes formatValue={(value)=>(value < 10 ? `0${value}` : value)} />:<Timer.Seconds formatValue={(value)=>(value < 10 ? `0${value}` : value)} /></div>
                    </div>
                    
                </div>
            )}
        </Timer>
    )
}

export default StopWatch