import React, { useEffect, useState } from "react";
import {
  Row,
  Navbar,
  Modal,
  ModalBody
} from "reactstrap";
import Handle from "../assets/handle.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Project Components 
import Grades from "./Grades"
import StopWatch from "./StopWatch"
import Logo from "../assets/DynamicAssessmentIcon.png"


const NavBar = ( props ) => {
  const [popoverOpen, setTogglePopover] = useState(false)
  const [ exitModalOpen, setExitModalOpen ] = useState(false)
  const togglePopover = () => { setTogglePopover(!popoverOpen) }

  const colors = [
    { color:"yellow-circle", code: "#F48B30" },
    { color:"brown-circle", code: "#5F3716" },
    { color:"purple-circle", code: "#6B328F" },
    { color:"red-circle", code: "#E8232B" },
    { color:"blue-circle", code: "#0C69B0" },
    { color:"green-circle", code: "#579869" },
    { color:"black-circle", code: "#000000" },
  ]
  const [selectedColor, setColor] = useState("#579869")

  const [hideNav, setHideNav] = useState(false);

  const handleNavHide = () => { setHideNav(!hideNav) }

  const handleColor = (color) => {
    setColor(color)
    props.setColor(color)
  }
  
  const isDataSelected = () => {
    return props.student.ID &&
          props.independentLevel.level &&
          props.targetLevel.level
  }

  return (
      <div style={{maxWidth:'100vw', position:'relative'}}>
        <Modal isOpen={exitModalOpen}>
          <ModalBody style={{borderRadius:12}}>
            <div className="row justify-content-between">
              <div className="col-12 mb-4 text-center">
                <h4>Are you sure you want to exit?</h4>
                Your current progress for this student will not be saved.
              </div>
              <div className="col-5">
                <button onClick={()=>setExitModalOpen(false)} className="btn btn-outline-secondary btn-block">Cancel</button>
              </div>
              <div className="col-5">
                <button onClick={()=>window.location.reload()} className="btn btn-outline-danger btn-block">Confirm</button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Navbar fixed="top"
          className={
            hideNav ?
            "navbar-tools hidden-nav row m-0 w-100 align-items-center"
            :
            "navbar-tools row m-0 w-100 align-items-center"
          }
        >
          <div style={{width:'10%'}}>
            <div className={
              props.isRecording
              ?
              "color-picker mic-circle red-color m-auto"
              :
              "color-picker mic-circle m-auto"
            }>
              <div className="center">
                <FontAwesomeIcon icon="microphone" size="2x" />
              </div>
            </div>
            <StopWatch style={
              props.isRecording ?
              { color: "#DE0627" }
              :
              { color: "#000000"}
            }/>
          </div>
          <div style={{width:'70%'}}>
            <Row className="row justify-content-center py-3 m-lg-auto">
              {
                colors.map((color, index) => {
                  return (
                      <div
                        id={`${index}-${color.color}`}
                        className={index !== colors.length - 1 ? `color-picker ${color.color} mr-2 mr-lg-4` : `color-picker ${color.color}`}
                        onClick={() => handleColor(`${color.code}`) }
                        key={index}
                      >
                        {
                          selectedColor === color.code
                          ?
                          <div className="center">
                            <FontAwesomeIcon icon="pencil-alt" style={{fontSize:28}} />
                          </div>
                          :
                          ''
                        }
                      </div>
                  )
                })
              }
            </Row>
          </div>
          <div style={{width:'20%'}}>
            <div className="row align-items-center w-100 button-row flex-nowrap">
              <div
                  className="evaluation-btn mr-2"
                  id="DataPopover"
                  onClick={() => { togglePopover() }}
                >
                  <img
                    src={ Logo }
                    className="center"
                    alt="Dynamic Assessment"
                    style={{ width: "100%", height: "100%"}}
                  />
              </div>
              <Grades
                isOpen={ popoverOpen }
                toggle={ togglePopover }
                setIL={ props.setIL }
                setTL={ props.setTL }
                targetLevel={ props.targetLevel }
                independentLevel={ props.independentLevel }
                students={ props.students }
                selectedStudent={ props.student }
                setSelectedStudent={ props.setSelectedStudent }
                teacher={props.teacher}
                lettersMap={props.lettersMap}
                setLettersMap={props.setLettersMap}
                submitSession={ props.submitSession }
                letters={ props.letters }
                sessionID={ props.sessionID }
                setLetters={ props.setLetters }
              />
              <div
                className="action-btn close-btn float-end"
                onClick={() => {
                  setExitModalOpen(true)
                  // isDataSelected() ?
                  // props.submitSession()
                  // :
                  // togglePopover()
                }}
              >
                <div className="center">
                  <strong>Exit</strong>
                </div>
              </div>
            </div>
          </div>
        
        </Navbar>
        <div className="nav-handle"
          onClick={()=> { handleNavHide() }}
        >
          <img src={Handle} alt="handle"/>
        </div>
    </div>
  );
};

export default NavBar;
