import { Col } from "reactstrap"
import LetterSoundButton from "./LetterSoundButton"

const VowelSection = ( { longVowelLetters, shortVowelLetters, showLetter, setShowLetter, onLetterButtonClick } ) => {

    return (
        <>
        <Col xs="12" className='p-2'>
            <span><strong>VOWELS</strong></span>
        </Col>
        <Col xs="12" className='px-2'>
            <span><strong>Long</strong></span>
        </Col>
        { longVowelLetters?.map((letterObj, index)=>{
            return(
                <Col key={index} xs="2" className='p-2'>
                    <LetterSoundButton showLetter={showLetter} setShowLetter={setShowLetter} onLetterButtonClick={onLetterButtonClick} letterObj={letterObj}/>
                </Col>
            )
        })}
        <Col xs="12" className='px-2'>
            <span><strong>Short</strong></span>
        </Col>
        { shortVowelLetters?.map((letterObj, index)=>{
            return(
                <Col key={index} xs="2" className='p-2'>
                    <LetterSoundButton showLetter={showLetter} setShowLetter={setShowLetter} onLetterButtonClick={onLetterButtonClick} letterObj={letterObj}/>
                </Col>
            )
        })}
        </>
    )
}

export default VowelSection;