import React, { useState, useEffect } from 'react';
import CanvasDraw from 'react-canvas-draw';
import { boardSize } from "../utils/ratios";
import { BRUSH_WIDTH } from "../utils/defaults";

const Board = (props) => {
    
    const [canvasSize, setCanvasSize] = useState({
        width: window.innerWidth * 0.9,
        height: (window.innerWidth * 0.9) / 16 * 9
    });

    // Update canvas size dynamically on window resize
    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth * 0.9; // 90% of the viewport width
            setCanvasSize({
                width: newWidth,
                height: (newWidth / 16) * 9  // Maintain 16:9 aspect ratio
            });
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleOnChange = (evt) => {
        const data = evt.getSaveData();
        const imageURL = evt.getDataURL("jpeg", "#ffffff");

        const childData = {
            "segments": data,
            "imageURL": imageURL,
        }

        let parentData = props.data

        parentData[props.index] = childData

        props.setData(parentData);
    }
    return (
        <CanvasDraw
            className="m-auto"
            // canvasWidth={ boardSize.width }
            // canvasHeight={ boardSize.height }
            canvasWidth={ '100vw' }
            canvasHeight={ '100vh' }
            hideGrid={ true }
            hideInterface={ true }
            onChange={(evt) => handleOnChange(evt) }
            brushColor={ props.color }
            brushRadius={ BRUSH_WIDTH }
            lazyRadius={ 5 }
            immediateLoading={ true }
            saveData={props.data[props.index].segments}
        />
    )
}

export default Board